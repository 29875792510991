import axios from 'axios'
import { useHistory } from "react-router-dom"
axios.defaults.baseURL = process.env.REACT_APP_API_URL
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { filterData } from '@src/common/dataTableFilter'
import toast from 'react-hot-toast'
import {ErrorToastContent} from "@src/common/toastmessage"
import { useNavigate } from "react-router-dom"

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
  const response = await axios.get('/api/users/list/all-data')
  return response.data
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  const response = await axios.get(`/customer/index?page=${params.page}`, params)

  if (response.data.status === 400) {
    toast(
      <ErrorToastContent
      message={response.data.message}/>
    )
    const list = []
    const total = []
    return {
      params,
      data: list,
      totalPages: total
    }
  } else {
    const datas = await filterData(response.data.customers, params)
    return {
      params,
      data: datas[0].list,
      totalPages: datas[0].total
    }
  }
})

export const getCustomer = createAsyncThunk('appUsers/getUser', async id => {
  const customerId = id
  const response = await axios.get(`/customer/index?page=${params.page}`, params)
  if (response.data.status === 400) {
     return toast(
        <ErrorToastContent
        message={response.data.message}/>
      )
  } else {
    return {
      data: response.data.customers
    }
  }
})

export const getProperty = createAsyncThunk('appUsers/getProperty', async params => {
  const response = await axios.get(`/customer/${params.userid}/property?page=${params.page}`, params)
  const datas = await filterData(response.data.property, params)
  if (response.data.status === 400) {
    toast(
      <ErrorToastContent
      message={response.data.message}/>
    )
    const list = []
    const total = []
    return {
      params,
      data: list,
      totalPages: total
    }
  } else {
    return {
      params,
      data: datas[0].list,
      totalPages: datas[0].total
    }
  }
})

export const getTask = createAsyncThunk('appUsers/getTask', async params => {
  const response = await axios.get(`/customer/${params.userid}/task?page=${params.page}`, params)
  const datas = await filterData(response.data.task, params)
  if (response.data.status === 400) {
    toast(
      <ErrorToastContent
      message={response.data.message}/>
    )
    const list = []
    const total = []
    return {
      params,
      data: list,
      totalPages: total
    }
  } else {
    return {
      params,
      data: datas[0].list,
      totalPages: datas[0].total
    }
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
  const customerId = id
  const response = await axios.get(`/customer/${customerId}/details`)
  if (response.data.status === 400) {
    toast(
      <ErrorToastContent
      message={response.data.message}/>
    )
    const list = ''
    return {
      data: list
    }
  } else {
    return {
      data: response.data
    }
  }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
  await axios.post('/apps/users/add-user', user)
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', async (id, { dispatch, getState }) => {
  await axios.delete('/apps/users/delete', { id })
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    propertyData: [],
    taskData: [],
    total: 1,
    params: {},
    allData: [],
    selectedUser: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getProperty.fulfilled, (state, action) => {
        state.propertyData = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getTask.fulfilled, (state, action) => {
        state.taskData = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload.data
        state.otherDatas = action.payload
      })
  }
})

export default appUsersSlice.reducer
